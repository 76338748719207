exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archive-jsx": () => import("./../../../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-hor-jsx": () => import("./../../../src/pages/hor.jsx" /* webpackChunkName: "component---src-pages-hor-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-jsx": () => import("./../../../src/pages/mobile.jsx" /* webpackChunkName: "component---src-pages-mobile-jsx" */),
  "component---src-templates-single-artwork-jsx": () => import("./../../../src/templates/single-artwork.jsx" /* webpackChunkName: "component---src-templates-single-artwork-jsx" */)
}

